<template>
  <van-popup
    position="bottom"
    v-model='isVisible'
    :style="{ height: '80%' }"
    closeable
    @close="$emit('update:isVisible', false)">
    <div id="agreement">
      <p align="center">
        <span class="tit">用户隐私协议</span>
      </p>

      <p>概述</p>
      <p>
        &nbsp; &nbsp;
        &nbsp;欢迎您使用闪借花平台/应用，（以下简称“闪借花公司”或“我们”）开发、管理和运营，为用户与第三方金融机构提供资金对接、信息撮合的服务平台，同时也是为与闪借花公司建立合作关系的金融机构提供金融产品或服务展示的技术服务平台，包括但不限于闪借花移动应用APP、闪借花-官微、闪借花H5页面及API合作页面等网络媒介平台(
        以下简称“闪借花”或本“平台”)。
      </p>
      <p>
        &nbsp; &nbsp;
        &nbsp;我们深知个人信息对您而言的重要性，也感谢您对我们的信任。我们将通过本政策向您说明闪借花平台收集、存储、共享、保护、使用您个人信息的目的、方式和范围，信息安全保护措施，以及我们为您提供访问、更新、删除等控制您个人信息的方法，说明您享有的权利，其中要点如下：
      </p>
      <p>
        &nbsp; &nbsp;
        &nbsp;&nbsp;&nbsp;1.为了便于您了解您在使用我们的服务时，我们需要收集的信息类型与用途，我们将结合具体服务向您逐一说明。
      </p>
      <p>
        &nbsp; &nbsp;
        &nbsp;&nbsp;&nbsp;2.为了向您提供服务所需，我们会按照合法、正当、必要的原则收集您的信息。
      </p>
      <p>
        &nbsp; &nbsp;
        &nbsp;&nbsp;&nbsp;3.如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意或确认第三方已经征得您的同意。
      </p>
      <p>
        4.如果为了向您提供服务而需要从第三方获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性；如果我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将征得您的明确同意。
      </p>
      <p>
        5.您可以通过本政策介绍的方式访问和管理您的信息、隐私管理功能、注销闪借花账户或进行投诉举报。
      </p>
      <p>&nbsp;</p>
      <p>
        &nbsp; &nbsp;
        &nbsp;本政策是对旧版《闪借花隐私权政策》的升级与修订。当您同意我们的注册协议及服务协议，或您访问、下载我们的闪借花平台或访问我们官方网站，或您通过我们自有客户服务渠道、第三方合作机构现有或将来可能开发的软件实际使用我们提供的任一产品或服务时，请您确认您已阅读并理解本政策条款,通过点击“确认”、“同意”(具体以页面显示名称为准)或以其他方式签署本政策，同意我们按照本政策来合法处理您的个人信息。我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款和个人敏感信息，采用粗体字进行标注以提示您注意。如果您不同意或者不理解本政策的部分或全部内容，请立即停止使用我们的产品和
        / 或服务。您可通过我们的联系方式就本政策相关事宜联系、咨询我们。
      </p>
      <p>
        &nbsp; &nbsp;
        &nbsp;本政策依据截至生效日适用的法律、法规制定，同时也参考了适用的国家及行业标准、国际协议及惯例。如国家法律法规对本政策内容有另行规定或您与我们另行约定的条款与本政策不一致的，以另行规定、约定为准。
      </p>
      <p>
        &nbsp; &nbsp;
        &nbsp;本政策涉及的产品和／或服务仅供成年人使用，请您在使用相关产品 /
        或服务前确保您是 18 周岁 ( 含 ) 以上人士。
        如您未达到上述年龄的，请您立即终止使用闪借花产品和 / 或服务。
      </p>
      <p>&nbsp;</p>
      <p>本政策包含以下内容：</p>
      <p>&nbsp;一、我们的承诺</p>
      <p>&nbsp;二、我们如何收集和使用个人信息</p>
      <p>&nbsp;三、我们如何存储个人信息</p>
      <p>&nbsp;四、我们如何共享、转让、公开披露个人信息</p>
      <p>&nbsp;五、我们如何保护个人信息</p>
      <p>&nbsp;六、我们如何使用COOKIES等自动收集工具</p>
      <p>&nbsp;七、您对个人信息的控制</p>
      <p>&nbsp;八、未成年人个人信息特别约定</p>
      <p>&nbsp;九、本政策的适用及更新</p>
      <p>&nbsp;十、我们的联系方式</p>
      <p>&nbsp;十一、本政策中关键词说明</p>
      <p>&nbsp;</p>
      <p>一、我们的承诺</p>
      <p>
        &nbsp;&nbsp;（一）我们仅在必要的、合理的范围内收集、处理您的个人信息
      </p>
      我们承诺仅在必要、合理的范围内收集、处理您的个人信息。我们收集、处理您的个人信息是为了遵守国家法律法规的规定，以及通过我们的平台向您提供个性化、更优质的产品或服务（包括支持我们开发新产品或服务，或完善已有产品或服务功能。）
      <p></p>
      <p>
        &nbsp;&nbsp;（二）我们仅在您知悉并同意的前提下收集、处理您的个人信息
        我们承诺会公开坦诚地告知您何种个人信息被收集、处理及其原因、方式。我们尊重您的选择，我们收集、处理您的个人信息的范围、目的及方式如有任何重大改变时，我们将及时告知您。
      </p>
      <p>
        &nbsp;&nbsp;（三）我们尊重您对个人信息的管理
        我们确保您提供的个人信息不被恶意篡改、损毁或缺失。我们理解并尊重您对个人信息的关切，并为您提供个人信息的查询、更正及删除等渠道。
      </p>
      <p>
        &nbsp;&nbsp;（四）我们将采取必要的措施保证您的个人信息安全
        我们承诺在能力范围内采取必要的安全措施，以保障您的个人信息安全，包括但不限于通过符合适用的法律法规及标准要求的硬件、软件、数据、人员、物理环境及其基础设施防护措施保护您的个人信息安全；不仅如此，我们还会与相关员工签署保密协议或相关承诺书并通过对员工进行安全意识培训，敦促其遵守本政策。
      </p>
      <p>&nbsp;</p>
      <p>二、我们如何收集和使用个人信息</p>
      <p>
        &nbsp;&nbsp;本政策所指的“个人信息”是以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。当您访问、下载我们的平台时，出于本政策所述的以下目的，您使用我们的产品或服务过程中，您会向我们主动提供您的个人信息，我们也会通过自动收集工具、第三方收集您的信息。
      </p>
      <p>&nbsp;</p>
      <p>
        &nbsp;&nbsp;请您理解，由于法律、法规及商业策略的变化，闪借花的功能也可能进行更迭和发展。如果我们要将您的个人信息用于本政策未载明的其它用途，或基于特定目的将收集而来的信息用于其他目的，我们会通过闪借花以合理的方式
        ( 如弹窗等形式 ) 向您告知，并在使用前再次征得您的同意。
      </p>
      <p>&nbsp;（一）收集使用个人信息的目的及范围</p>
      <p>&nbsp;&nbsp;1.账号注册及登录</p>
      <p>
        &nbsp;&nbsp;为了确认您的身份，为建立您的闪借花账户并对账户进行验证以实现登录目的，维护您的权益，在您注册闪借花服务时，您须至少提供手机号码、验证码，并创建密码为向您提供快捷安全的登录方式，您还可以使用“本机号码一键登录”的登录方式登录。您授权手机运营商根据网络认证当前本机sim卡的手机号码，登录时免除输入手机号、验证码操作，一键登录并注册，闪借花基于您的授权，根据运营商提供的手机号和服务，为您创建账号，提供注册、登录服务。一键登录功能为您提供登录便利的同时确保手机号账号在本机上登录，不会被他人盗用。为了让您的账号更具个性化，您还可以主动上传您的用户头像。在您启动闪借花时，为保证您正常使用应用，您需开通手机/电话权限,用于识别手机设备和本机号码，保障金融信息安全；开通存储权限，实现APP图片的缓存和取用，降低流量消耗;在您登录同时，为识别和验证您的用户身份，我们将通过【API】采集您的以下信息：唯一设备识别码以及本机号码，以保障您的金融信息安全。请您知悉，为了防止欺诈风险，避免您的账号被非法盗用进而导致您账号中的信息泄露，同时保障您的账号财产安全，我们将在借款申请等各个业务环节实时收集您的设备识别码及本机号码，每个环节仅单次获取。具体注册方式及流程请参见《用户注册协议》中的相关内容。如果您仅需浏览闪借花平台，您不需要注册成为我们的用户及提供上述信息。
      </p>
      <p>
        &nbsp;&nbsp;您提供的上述信息，将在您使用闪借花平台产品和服务期间持续授权我们使用。在您注销账号时，我们将停止使用并或对您的个人信息进行匿名化处理，法律法规另有规定的除外。
      </p>
      <p>&nbsp;&nbsp;2.修改登录密码</p>
      <p>
        &nbsp;&nbsp;您可通过【设置】进入【修改登录密码】操作界面，您需核实您的注册手机号、录入旧登录密码及您重新设置的登录密码后，点击“提交”后即可完成修改密码。
      </p>
      <p>&nbsp;&nbsp;3.登录密码找回</p>
      <p>
        &nbsp;&nbsp;如果您忘记登录密码需要找回登录密码时，您可在【修改登录密码-忘记密码】界面进行操作，届时我们需要验证您的身份。您需要向我们提供注册时提供的手机号码，我们会向您注册时提供的手机号码发送短信验证码，待验证成功后，您通过输入新的登录密码进行密码重置。或您可以选择提供手机号码、已绑定的身份证号码和使用您的人脸信息进行身份验证重置密码。当您使用刷脸认证时，为协助合作金融机构或担保公司验证您的人脸信息，需要您开通手机获取相机权限。
      </p>
      <p>&nbsp;&nbsp;4.重置交易密码</p>
      <p>
        &nbsp;&nbsp;您在申请信贷产品、商城购物时需录入您预先设置的交易密码，若您忘记交易密码或需要更换交易密码时，可通过【设置】进入【重置交易密码】操作界面，您需提供注册手机号运营商提供的验证码、身份证号码完成身份验证后，您可录入您重新设置的交易密码完成重置交易密码。
      </p>
      <p>&nbsp;&nbsp;5.为您提供消费信贷产品申请、推荐服务</p>
      <p>&nbsp;&nbsp;（1）协助合作金融机构或担保公司完成实名验证管理</p>
      <p>
        &nbsp;&nbsp;为了让您更安全、便捷地申请闪借花推荐的特定消费信贷产品、推荐服务时，为了满足反洗钱和对您的实名制验证管理，您还需要进一步向我们的合作金融机构或担保公司提供您的真实姓名、身份证件信息（身份证号码和有效期、身份证证件正反面影像件、身份证发证机关）、本人人脸认证信息、银行卡及银行卡实名认证信息（银行卡卡号、银行卡开户行、银行预留手机号码及短信验证码）以完成实名验证，在此过程中可能会请求您开启相机、麦克风、存储权限以实现上述信息的收集。为了验证您提供信息的准确性和完整性，并保障您的账号安全，我们合作金融机构或担保公司会与合法留存您身份信息的国家机关、金融机构、企事业单位进行多重交叉验证。如您不提供上述个人信息以使我们的合作金融机构或担保公司完成交叉核验您的身份，根据国家相关法律法规实名制管理的要求，您将无法正常使用闪借花平台的金融信贷产品申请、推荐金融产品和/或服务功能，但不影响您使用我们提供的其他服务。
      </p>
      <p>&nbsp;&nbsp;（2）协助金融机构风险审核和信用评估服务</p>
      <p>
        &nbsp;&nbsp;您通过闪借花平台“我要借钱”申请推荐的消费信贷产品服务，且您必须准确输入您事先设置的交易密码方可完成信贷产品的申请操作。申请闪借花进行信用额度评估时，为了尽量科学、全面、客观、公正地协助金融机构向您提供信用评估服务，满足您多层次、多样化的服务需求，合作金融机构或担保公司需要收集与您信用相关的真实、准确、全面的信息，用于确定您资信状况及未来潜在的争议解决的个人信息，或者用于帮助您依法依约履行合同义务及向您追索到期债务的个人信息及其他信息，协助合作金融机构或担保公司开展风控
        (包括通过比对联系人情况的方式判断是否存在团体欺诈风险、账户风险识别)，您需要主动向合作金融机构或担保公司提交您的活体人脸信息、姓名、身份证号码、身份证签发机关及有效期、身份证证件正反面影像、联系地址、婚姻状况、教育程度、电子邮箱、联系人姓名及联系方式、银行卡实名验证信息（卡号、预留手机号及验证码）。其中，电子邮箱和支付宝芝麻分由您自愿选择提交。为了核实您的征信情况，您需允许提供贷款服务的合作金融机构或担保公司采集您的征信信息。
      </p>
      <p>
        &nbsp;&nbsp;在此过程中，为了您准确提交活体刷脸信息、身份证影像信息，您应允许合作金融机构或担保公司提供的程序相机权限，我们届时会弹窗提示经您同意后自主开启权限。为了识别您的账户风险，您可允许获取通话记录读取权限，我们届时会弹窗提示经您同意后自主开启权限，若您拒绝获取通话记录读取权限的将不影响您使用本服务，但合作金融机构或担保公司可能无法对您做出准确的风险评估。为方便您直接从通讯录选择联系人免于手动输入联系人手机号码的便利，您可选择允许获取通讯录读取权限，我们届时会弹窗提示经您同意后自主开启权限，合作金融机构或担保公司会采集您的通讯录中联系人手机号码、姓名自动填充。请您确保您在向金融机构提供您的联系人时已经获得您的联系人的同意，我们或我们的合作方可以通过您提供的联系方式进行联系。如您向我们提供的联系人及其手机号未经过本人同意，我们与您的联系人进行联系所发生的风险及责任将由您承担。当您违反我们的约定或您通过闪借花申请的贷款发生逾期时，为维护我们及/或贷款人的合法权益，合作金融机构或担保公司有权委托我们向合作的律师事务所、催收机构及我们认为可向您传达信息的联系人披露您的违约信息（包含贷款逾期信息）。为确保您联系地址的准确性，为您推荐区域性个性化优质借款服务，我们需要您通过开启“位置权限”向闪借花提供您的地理位置信息，我们会弹窗提示经您同意后再收集并且我们不会记录您的行踪轨迹。
      </p>
      <p>
        &nbsp;&nbsp;上述由您提供和允许采集的个人信息将被用来审核、评估您的信贷资格、信用及偿付能力，进而帮助闪借花平台和合作金融机构或担保公司做好风险管理工作。如您不提交上述信息，您将无法正常使用闪借花平台的消费信贷产品申请、推荐金融产品和/或服务功能，但不影响您使用我们提供的其他服务。
      </p>
      <p>&nbsp;&nbsp;（3）提额认证服务</p>
      <p>
        &nbsp;&nbsp;您可以通过合作金融机构或担保公司的提额认证服务，主动向合作金融机构或担保公司提交一项或多项认证信息，其中刷脸认证、身份认证、联系认证、绑卡认证为您个人信用评估必填信息，内容与“我要借款”服务功能所需信息一致。在此，您可以通过【首页-提额认证】功能模块主动上传全部或部分的个人资料以补充、增强您的资信水平及履约能力，从而满足您希望提高借款额度的融资需求。您可补充提供的个人资料有：其他贷款、信用卡、第三方信用报告、车辆信息、驾驶证、职业信息。您若选择补充提供其他贷款资料的，您需勾选出“有”或“无“；您若选择补充提供信用卡资料的，您需勾选您需勾选出“有”或“无“；您若选择补充提供第三方报告的，您需上传银行征信报告及/或天下信用报告影像件；您若选择补充提供车辆信息的，您需上传行使证的首页及副页影像件，我们将通过影像件采集您的车辆类型及车牌号码等行驶证记载的全部信息；；您若选择补充提供驾驶证信息，您需上传驾驶员的首页及副页影像件，我们将通过影像件采集您的驾驶证证件号、签发机构、有效期等驾驶证记载的全部信息；若您选择补充提供职业信息的，您需提供您的职业身份、税前工资收入、工资外收入、公司名称、公司详细地址、公司电话、公司邮箱（如有），最后输入手机运营商短信验证码完成信息提供。
      </p>
      <p>
        &nbsp;&nbsp;您可以通过“支付宝认证”、“京东认证”、“微信认证”及其他第三方认证功能模块，自愿向合作金融机构或担保公司提交您的微信支付分、京东小白分、支付宝芝麻分等第三方认证信用分（名称以微信、京东、支付宝等第三方认证界面展示名称为准），包括通过本人登录支付宝、京东、微信及其他第三方APP并对支付宝APP中个人信息页面及芝麻分页面、京东APP实名认证页面及小白信用页面、微信APP中账号与安全页面及支付分的截图信息，在此过程中合作金融机构或担保公司会识别截图的个人信息数据。
      </p>
      <p>
        &nbsp;&nbsp;提额认证资料非必填信息，为您提升自身信用评估，自愿提交，您理解并同意我们的合作金融机构或担保公司基于您提高借款额度的需求对上述信息收集、使用并通过第三方合法渠道进行核实，此类信息仅用于提额评估服务。如您不提交上述信息，不会影响您现有额度及使用我们提供的其他服务。
      </p>
      <p>&nbsp;&nbsp;（4）还款服务</p>
      <p>
        &nbsp;&nbsp;你可登录【首页-立即还款】进入【还款计划】操作页勾选您需要还款的借款订单，点击“还款”进入【还款确认】页，根据向您提供贷款的不同金融机构的要求，您需要进行银行账户绑定和/或银行二类电子账户开立、绑定、转账、还款，您需要向闪借花平台提供您用于收款和还款的个人银行卡信息（信用卡或借记卡卡号，预留手机号及验证码）。为您提供贷款的金融机构和支付服务的机构，将收集您的真实姓名、身份证号码及身份证证据影像件信息、银行卡开户行、开户地、开户名称、卡号信息，并对您的身份信息及银行账户信息进行验证。
      </p>
      <p>&nbsp;&nbsp;（5）银行卡管理</p>
      <p>
        &nbsp;&nbsp;当您使用银行卡管理服务，添加储蓄卡时，您需要提供储蓄卡卡号、银行预留手机号及验证码；添加信用卡时，您需提供卡号、发卡行名称、账单日（选填）。如您不提供前述信息，将无法使用添加银行卡服务，但不影响您使用我们提供的其他服务。
      </p>
      <p>
        &nbsp;&nbsp;（6）为完成资金结算、账务核对以及提供信息报告和满足相关法律法规的信息披露要求，我们和/或我们委托的为您提供支付服务的第三方支付机构/银行、贷款人将收集您的借款记录、还款记录、积分记录以及您账户中的财产信息。
      </p>
      <p>&nbsp;&nbsp;6.商城服务</p>
      <p>
        &nbsp;&nbsp;目前，我们与第三方（运营主体包括“舶茂国际贸易（上海）有限公司”，具体以商城中列明的服务商为准）一起合作为您提供特惠商城/正品特卖商城服务。为使用商城服务购买商品，您需在【支付确认】操作页面选择您用于支付的银行卡及您该银行卡的预留手机号，并录入银行预留手机号运营商的短信验证码完成自主支付。为了实现商城物流配送，我们会将您的手机号共享给第三方商城服务商，第三方服务商会收集收货人姓名、联系电话、收货详细地址，如您不提供上述信息，将无法购买商品，但不影响您浏览或查看商品。
      </p>
      <p>&nbsp;&nbsp;7.借贷引流类服务</p>
      <p>
        &nbsp;&nbsp;当您使用【合作机构借钱】服务中由持牌金融机构、网络借贷信息中介方提供的符合要求的出借人以及其他合法的金融机构主体（以下简称“第三方资金出借主体”）提供的借贷引流类（例如小赢卡贷）服务时，为了向您提供该借贷引流服务，我们可能会经您授权同意后向第三方资金出借主体共享您在注册、申请借款以及使用借贷引流服务过程中主动提供以及授权我们收集的信息（包括手机号），我们将使用国际通用加密技术向第三方资金出借主体传输上述您的个人信息。同时，您需要根据第三方资金出借主体的要求主动提供个人手机号、身份证、银行卡等对您的身份进行认证。为了使您更加充分地了解您的权利，建议您仔细阅读第三方资金出借主体的隐私政策。同时，为了向您展示和提供更便捷的查询和申请【合作机构】服务，我们可能基于您的授权获取您在本服务中的贷款信息（包括借款金额、还款计划、逾期信息等）。
      </p>
      <p>&nbsp;&nbsp;8.贷前必查/信用报告服务</p>
      <p>
        &nbsp;&nbsp;贷前必查/信用报告服务为合作第三方（包括鹏元征信有限公司）提供的个人信用信息查询服务，您使用本服务时，我们会收集您的手机号码，并经您授权后共享给服务提供方，服务提供方会收集您的姓名、身份证号、人脸验证信息以确保您是本人申请查看个人信用报告信息，请您仔细阅读服务方的服务协议及隐私政策，如您不提供上述信息，将无法查看信用报告，但不影响您使用我们的其他服务或功能。
      </p>
      <p>&nbsp;&nbsp;9.其他第三方服务</p>
      <p>
        &nbsp;&nbsp;闪借花平台提供的其他服务如极速办卡、闪借花钱包、会员中心、保险、省币兑换、理财产品推荐服务包含第三方网站、产品或服务链接，由第三方提供的服务，在申请上述服务时，我们会经您明确授权后共享您在注册、申请借款以及使用其他服务过程中主动提供以及授权我们收集的信息给第三方，同时，您可能还需要根据第三方的要求提供信息，具体信息类型以第三方要求为准，我们不收集、不存储您在第三方服务业务填写和提交的任何个人信息。当您查看第三方创建的网页或使用第三方应用程序时，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们的控制，且它们的使用不受本政策的约束。我们会依据法律法规的要求采取必要措施对相关网站进行审查（包括审查网站经营者的基本资质、通过技术手段对相关网站的安全情况进行合理的初步识别、督促该等网站经营者根据法律规定保护您的个人信息安全）。如您发现这些第三方创建的网页或第三方应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
      </p>
      <p>&nbsp;&nbsp;10.我们从其他第三方获得您的个人信息的情形</p>
      <p>
        &nbsp;&nbsp;为更好地向您提供产品和服务，也为了闪借花平台自身的风险防控，以及保护各方的合法权益，除了您在闪借花平台上明示同意并授权闪借花平台可以收集和使用通过您自主填写和提供的个人信息和资料外，在经过您的授权下（含在线签署的电子文件），闪借花平台合作金融机构或担保公司可能从合法持有您个人信息的第三方机构查询、收集您的征信信息、借款信息、运营商信息、信用评分等其他与借款审批、管理相关的个人信息，用于对您的信贷资格、信用及偿付能力进行审核，并提供匹配的信贷服务以及在后期对您进行必要的催收通知服务。该等第三方基于向提供前述查询服务之目的，可能会对经您授权提供的个人信息进行合法、必要的处理。同时，合作金融机构或担保公司将通过第三方合作方（包括合作机构、行业协会）对您提交或闪借花平台收集的用户信息（包括您的个人身份信息）进行核实、验证，并对获得的核实、验证结果进行查看、收集、使用和留存操作。
      </p>
      <p>
        &nbsp;&nbsp;闪借花各项业务场景或产品功能所收集、使用用户信息列举详见本政策附件一、第三方共享您的信息逐项列举，详见本政策附件二。
      </p>
      <p>&nbsp;&nbsp;11.安全管理</p>
      <p>
        &nbsp;&nbsp;为了保障向您提供的服务的安全稳定运行，预防交易和资金风险，我们需要自动收集或记录您使用的闪借花服务类别、方式及设备型号、IP地址、设备软件版本信息、设备标识符、操作行为、软件安装情况以及其他与闪借花服务相关的日志信息。获取上述信息前我们将通过弹窗等形式提示并征得您的同意，如您不同意我们记录前述信息，可能无法完成风控验证和安全管理，但不影响您使用我们提供的其他服务。
      </p>
      <p>
        &nbsp;&nbsp;各相关业务功能可能开启您的设备访问权限的逐项列举，详见附件三。
      </p>
      <p>&nbsp;&nbsp;12.第三方SDK或类似应用程序</p>
      <p>
        &nbsp;&nbsp;闪借花平台包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由合作方提供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。合作方SDK收集的设备信息通常包括：SDK或API版本、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、iOS供应商标识符（IDFV)、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC)地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、移动网络/国家代码、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。闪借花平台不收集、不存储您在第三方SDK服务页面填写和提交的任何个人信息。为了最大程度保障您的信息安全，我们建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。
      </p>
      <p>
        &nbsp;&nbsp;闪借花平台使用第三方SDK情况及隐私政策、服务协议等，详见附件四。
      </p>
      <p>&nbsp;&nbsp;13.客户服务</p>
      <p>
        &nbsp;&nbsp;为保护您的帐户安全，提升您的服务体验及改进服务质量，或者为您推荐更优质或更适合的服务，在您咨询服务时，我们的客服会使用您的信息（姓名、手机号码、身份证号码）与您核验身份。为解答您咨询的问题，我们可能需查看您使用闪借花服务的记录，有时还需要向涉及的商户进行核实或调查。我们会收集您与客户服务团队联系时提供的信息及您参与问卷调查时向我们发送的信息。
      </p>
      <p>&nbsp;&nbsp;14.为您展示和推送商业信息</p>
      <p>
        &nbsp;&nbsp;为更好地向您提供全面的服务，您同意我们通过您的电话、短信、站内信、电子邮件等方式，合理地向您发送商业性服务信息、平台最新的有关管理、产品内容及其他平台认为有必要告知注册会员的服务通知。如果您不想接收我们的商业推送信息，您可以通过【我的→设置→隐私管理→APP通知】来关闭站内信推送，也可以联系我们的在线客服或电话客服关闭此功能。
      </p>
      <p>
        &nbsp;&nbsp;15.改善我们的产品和服务，并开展内部审计、数据分析和研究
      </p>
      <p>
        &nbsp;&nbsp;我们使用收集的信息来提供并改进我们及我们关联方、合作方提供的产品和服务，并进行必要的业务运营，例如运营产品或提供服务，评估、维护和改进产品和服务的性能，开发新的产品和服务、提供客户支持等。
      </p>
      <p>
        &nbsp;&nbsp;为改进我们及我们关联方、合作方的产品和服务，我们也可能会对产品使用情况进行统计和分析。同时，我们可能会与公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。
      </p>
      <p>
        &nbsp;&nbsp;我们会将所收集到的信息用于大数据分析。例如，我们将收集到的信息用于分析形成不包含任何个人信息的统计类产品。我们可能对外公开并与我们的合作机构分享经统计加工后不含身份识别内容的大数据分析信息。
      </p>
      <p>&nbsp;&nbsp;16.委托处理</p>
      <p>
        &nbsp;&nbsp;第三方金融机构及担保公司为了对您作出更准确的信用评估，或提高本应用的服务质量、降低服务成本，合作金融机构或担保公司（以下统称“委托机构”）经您同意并授权后委托我们协助完成部分服务或职责,包括但不限于（1）委托我们按照本合同的约定将委托方已经采取了脱敏、去标识化等保护措施的您的信息进行分析、加工和处理；（2）当您发生违反您已签署的协议约定而产生债务时，委托我们对您进行债务到期提醒或追索。
      </p>
      <p>&nbsp;&nbsp;17.其他</p>
      <p>
        &nbsp;&nbsp;请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵盖的其他服务，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程、协议约定的方式另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。
      </p>
      <p>&nbsp;&nbsp;（二）我们通过以下方式收集个人信息</p>
      <p>&nbsp;&nbsp;1.您直接提供给我们的信息</p>
      <p>
        &nbsp;&nbsp;您通过闪借花平台注册我们的平台账户、申请或使用我们的产品或服务，或通过电子邮件、电话或其他方式联系我们时，可能向我们提供个人信息。若您不提供这些信息或提供的信息有误，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
      </p>
      <p>&nbsp;&nbsp;2.来自我们平台主动收集的信息</p>
      <p>
        &nbsp;&nbsp;除了您提供给我们的个人信息外，我们可能直接通过COOKIES等自动收集工具收集您的信息，主要是您的用户ID以及与我们的服务相关的日志信息。若您拒绝我们收集此类信息，我们将无法全部或部分为您提供相关产品或服务的功能，但不会影响我们向您提供其他的服务。
      </p>
      <p>&nbsp;&nbsp;3.来自商业合作伙伴的信息</p>
      <p>
        &nbsp;&nbsp;我们可能会从商业合作伙伴处获得您的信息，包括您在其他商业合作伙伴处的注册信息、交易信息（包括交易的商品名称及金额等）、信用评分信息等。若您拒绝授权或同意我们获取这些信息，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型，但不会影响我们向您提供其他的服务。
      </p>
      <p>&nbsp;&nbsp;4.来自其他合法第三方的信息</p>
      <p>
        &nbsp;&nbsp;事先征得您的同意后，合作金融机构或担保公司可能会通过其他合法第三方对您提供的信息进行验证，或者通过您提供的信息在合法的第三方渠道查询您的其他信息，包括您的征信信息、运营商信息、收入信息、公积金及社保信息、信用评分等信息。
      </p>
      <p>
        &nbsp;&nbsp;5.在法律法规允许的范围内，我们可能会在以下情形中收集并使用您的个人信息无需经过您的授权同意：
      </p>
      <p>&nbsp;&nbsp;（1）与我们履行法律法规规定的义务相关的；</p>
      <p>&nbsp;&nbsp;（2）与国家安全、国防安全直接相关的；</p>
      <p>&nbsp;&nbsp;（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>&nbsp;&nbsp;（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>
        &nbsp;&nbsp;（5）出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；
      </p>
      <p>&nbsp;&nbsp;（6）所收集的个人信息是您自行向社会公众公开的；</p>
      <p>
        &nbsp;&nbsp;（7）从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；
      </p>
      <p>&nbsp;&nbsp;（8）根据您的要求签订和履行合同所必需的；</p>
      <p>
        &nbsp;&nbsp;（9）用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置服务的故障；
      </p>
      <p>&nbsp;&nbsp;（10）法律法规规定的其他情形。</p>
      <p>&nbsp;&nbsp;（三）我们如何使用个人信息</p>
      <p>
        &nbsp;&nbsp;为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账户和资金安全，我们会在以下情形中使用您的信息：
      </p>
      <p>
        &nbsp;&nbsp;1.实现本政策中“我们收集个人信息的目的及范围”所述目的。
      </p>
      <p>
        &nbsp;&nbsp;2.向合法第三方机构进行身份信息的验证及匹配，便于我们确认您的资信状况或用于可能的争议解决。
      </p>
      <p>&nbsp;&nbsp;3.用于帮助您依法依约履行合同义务及向您追索到期债务。</p>
      <p>
        &nbsp;&nbsp;4.我们会采取脱敏、去标识化等方式对您的信息进行综合统计、分析加工，以便为您提供更加准确、个性、流畅及便捷的服务，或帮助我们评估、改善或设计服务及运营活动；
      </p>
      <p>
        &nbsp;&nbsp;5.我们可能会基于您的授权及合作金融机构或担保公司委托处理要求采取脱敏、去标识化处理您的信息，用于大数据分析服务，形成不包含任何个人信息或身份识别内容的统计类或风控类产品。
      </p>
      <p>
        &nbsp;&nbsp;6.与您沟通或回应您的疑问和请求，或是向您提供更新信息，包括但不限于闪借花用户服务协议、本政策、条款和条件方面的变动或其他我们认为需要告知您的变动。
      </p>
      <p>
        &nbsp;&nbsp;7.用于监测我们平台的性能和稳定性，优化性能、支持、改进线上服务质量。
      </p>
      <p>
        &nbsp;&nbsp;8.为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途。
      </p>
      <p>
        &nbsp;&nbsp;9.用于我们的内部风险控制，建立数据模型或者建立性格测试模型，用于特征分析和用户画像，帮助我们进行内部风控和反欺诈识别服务。
      </p>
      <p>&nbsp;&nbsp;10. 根据法律法规或监管要求向相关部门进行报告。</p>
      <p>
        &nbsp;&nbsp;11.向合作金融机构或担保公司提供合规和风控需要的必要数据。
      </p>

      <p>&nbsp;&nbsp;三、我们如何存储个人信息</p>
      <p>&nbsp;&nbsp;（一）保存的地域</p>
      <p>
        &nbsp;&nbsp;您的个人信息储存于中华人民共和国境内，我们不会在境外存储您的个人信息。
      </p>
      <p>&nbsp;&nbsp;（二）保存的期限</p>
      <p>
        &nbsp;&nbsp;除非法律法规另有规定，我们将按如下方式及期间保存您的信息：
      </p>
      <p>
        &nbsp;&nbsp;1.您使用我们产品或服务期间，我们将综合考虑为您提供产品或服务的必要性，以最短的必要期限内根据业务需要为您保留个人信息，当您的个人信息超出我们所保存的期限后，我们会对您的个人信息进行匿名化处理；
      </p>
      <p>
        &nbsp;&nbsp;2.在停止使用我们服务后，您有权自主决定在相关技术设置允许条件下注销账户，但您在使用我们的服务过程中留存的交易记录（包括但不限于借款还款信息）除外；
      </p>
      <p>
        &nbsp;&nbsp;3.进一步的，即便有第（2）款之规定，在某些特定情况下，有且仅为了遵守国家安全、网络安全等法律规定的法定义务或政府命令，我们仍将继续保存您的信息，直至法定义务免除。
      </p>
      <p>&nbsp;&nbsp;（三）跨境转移</p>
      <p>
        &nbsp;&nbsp;除非获得您的特别同意，或者满足法律规定的评估及许可程序，我们不会跨境转移您的个人信息。
      </p>

      <p>&nbsp;&nbsp;四、我们如何共享、转让、公开披露个人信息</p>
      <p>&nbsp;&nbsp;（一）共享</p>
      <p>
        &nbsp;&nbsp;我们承诺对您的信息进行严格保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息：
      </p>
      <p>
        &nbsp;&nbsp;1.事先获得您的明确授权或同意：获得您的明确同意后，我们会与相关方共享您的个人信息,共享个人信息的范围将在获取您的授权或同意时说明展示；
      </p>
      <p>
        &nbsp;&nbsp;2.在法定情形下的共享：根据适用的法律法规、法律程序、司法裁定、政府的强制命令或行业自律要求而需共享您的个人信息，共享个人信息的范围将根据法律法规及相关行政或司法部门要求而定；
      </p>
      <p>
        &nbsp;&nbsp;3.在法律要求或允许的范围内，为了保护我们及您或社会公众的利益、财产或安全免遭损害而有必要提供您的个人信息给第三方，但法律法规明确禁止提供的除外；
      </p>
      <p>
        &nbsp;&nbsp;4.根据法律规定及合理商业习惯，在闪借花的运营主体与其他公司合并或被其收购或进行其他资本市场活动时，以及其他情形下闪借花接受来自其他主体的尽职调查时，您同意我们根据业务的需要把您的信息提供给必要的主体(如审计、法律等外部专业中介机构)，但我们会通过和这些主体签署保密协议等方式要求其对该等信息采取与本政策同等的保密措施，并要求其在完成必要工作后在合理的期限内删除您的信息；
      </p>
      <p>
        &nbsp;&nbsp;5.第三方合作机构共享：闪借花平台的某些产品和/或服务将由我们的供应商及合作伙伴(以下简称"第三方")提供，为此目的，我们可能会与第三方合作机构共享您的相关个人信息以向您提供具体产品或服务。
      </p>
      <p>
        &nbsp;&nbsp;如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。同时，我们会与第三方合作机构签署严格的数据安全和保密协定，要求他们严格按照我们的说明、本政策以及其他任何相关的保密和采取安全措施的要求来处理您的个人信息。我们将要求我们的第三方合作机构不得将共享的您的个人信息用于本政策目的以外的任何其他用途。另外，在特定共享场景，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意，或确认第三方已经征得您的同意。确认协议、文案确认及弹窗提示方案将构成本政策的有效组成部分。如果您拒绝我们在提供服务时与第三方合作机构共享必要的您的个人信息，将可能导致您无法使用该第三方合作机构提供的服务，但不影响您使用其他闪借花产品和/或服务。
      </p>
      <p>&nbsp;&nbsp;与我们共享信息的第三方合作机构主要包括以下类型：</p>
      <p>
        &nbsp;&nbsp;（1）技术服务类合作伙伴。为了开展身份验证活动，我们可能将您个人信息提供给三方身份验证服务提供商。基于您的授权及设定，收取您的位置信息及设备信息并提供给技术服务商，特殊情境下会包含您另行填写的个人信息，以获取他们的支持，这些支持包括但不限于提供技术基础设施服务、提供产品内或产品链接后的功能型服务，分析我们线上服务的使用方式、衡量广告和服务的有效性、提供客户服务和调查与分析。
      </p>
      <p>
        &nbsp;&nbsp;（2）
        广告、数据分析类合作伙伴。未经您的授权，我们不会将您的个人信息与提供广告、分析服务的非合作伙伴共享。但我们可能会将使用您的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助其在不识别您个人身份信息的前提下提升广告及服务信息的精准度及有效触达率。
      </p>
      <p>
        &nbsp;&nbsp;（3）
        金融、担保、征信服务类合作伙伴。根据您的授权，或您与相关合作伙伴签订的协议或文件，或基于法律法规、法律程序的要求或强制性的政府要求或司法裁定，或金融、征信审查等机构的合法需求，对您的个人信息进行查询、综合统计、分析或加工等处理，并将数据结果分享给金融、征信服务类合作伙伴，用于判定您的资信状况，筛选优质用户或降低信贷坏账等。
      </p>
      <p>
        &nbsp;&nbsp;（4）
        债权收购、追索类合作伙伴。基于您的授权或同意，我们可能会将您的个人信息提供给我们的债权收购、追索类合作伙伴，他们可能会在债权收购、追索之前、之后或者过程中对您的个人信息进行查询、统计、分析或评估，用于判断您的资信状况，并最终确定我们与该类合作伙伴的合作方式及实现合作目的。
      </p>
      <p>&nbsp;&nbsp;（二）转让</p>
      <p>&nbsp;&nbsp;我们不会对外转让您的个人信息，但以下情形除外：</p>
      <p>&nbsp;&nbsp;1.事先获得您的明确授权或同意；</p>
      <p>
        &nbsp;&nbsp;2.根据所适用的法律法规、行业规定、法律程序要求、强制性行政或司法要求所必须要求提供的；
      </p>
      <p>
        &nbsp;&nbsp;3.如果涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将提前通过公告等方式告知您相关情况，同时，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </p>
      <p>&nbsp;&nbsp;（三）公开披露</p>
      <p>&nbsp;&nbsp;除非获取您的明确同意，我们不会公开披露您的个人信息。</p>
      <p>
        &nbsp;&nbsp;基于法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会向有权机关披露您的个人信息。但我们保证，在上述情况发生时，我们会要求披露请求方必须出具与之相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施。
      </p>
      <p>&nbsp;&nbsp;(四)共享、转让、公开披露个人信息时征得授权同意的例外</p>
      <p>
        &nbsp;&nbsp;根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下共享、转让、公开披露您的个人信息：
      </p>
      <p>&nbsp;&nbsp;1.与我们履行法律法规规定的义务相关的；</p>
      <p>&nbsp;&nbsp;2.与国家安全、国防安全直接相关的；</p>
      <p>&nbsp;&nbsp;3.与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>&nbsp;&nbsp;4.与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>
        &nbsp;&nbsp;5.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </p>
      <p>&nbsp;&nbsp;6.您自行向社会公众公开的个人信息；</p>
      <p>
        &nbsp;&nbsp;7.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>

      <p>&nbsp;&nbsp;五、我们如何保护个人信息</p>
      <p>
        &nbsp;&nbsp;我们获得了国际权威的ISO27001信息安全体系标准认证和信息系统安全等级保护(三级)认证，我们承诺我们将使信息安全保护达到业界领先的安全水平。为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。
      </p>
      <p>&nbsp;&nbsp;（一）我们采取的技术措施与数据安全措施</p>
      <p>
        &nbsp;&nbsp;1.我们采取了符合法律要求的技术措施及手段来保障您的信息在整理、保存、加工等各环节的安全。包括但不限于制定了严格的内部信息管理流程、对数据进行加密、定岗定责、控制访问权限、采取安全措施等，以防止其丢失、被非法使用、受到未授权访问或泄漏、被篡改或毁坏。
      </p>
      <p>
        &nbsp;&nbsp;2.我们对所有员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。也通过监控和审计机制来对数据进行全面安全控制，履行个人信息访问权限审批与控制措施，防止您的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。
      </p>
      <p>
        &nbsp;&nbsp;3.我们仅允许有必要知晓这些信息的人员，在采取合理的措施验证身份之后，按授予的权限级别访问或使用这些信息。同时，我们会严格要求他们履行保密及安全义务，如果未能履行这些义务，其会被追究法律责任或被终止与我们的劳动、合作关系。
      </p>
      <p>
        &nbsp;&nbsp;4.为了保护您的信息安全，如果您的个人信息有误，我们会在严格验证并核实申请人身份后，根据您的要求访问、修正或删除相关信息（除非我们出于合法的原因而必须保留这些个人信息）。
      </p>
      <p>
        &nbsp;&nbsp;5.我们会定期进行数据安全能力评估/信息安全风险评估，确保安全保护系统始终处于完备状态。
      </p>
      <p>
        &nbsp;&nbsp;6.我们提醒您注意，互联网并非绝对安全的环境，当您通过电子邮件、短信等与其他用户交互信息时，不确定第三方软件对信息的传递是否完全加密，请注意确保您个人信息的安全，我们建议您不要通过此类方式发送个人信息，以免个人信息泄露。请使用复杂密码，协助我们保证您的账户以及个人信息安全。
      </p>
      <p>&nbsp;&nbsp;(二)安全事件处置</p>
      <p>
        &nbsp;&nbsp;1.
        我们将尽力确保您发送给我们的任何信息的安全性，但请您理解，由于技术的限制以及在互联网行业可能存在的各种恶意手段，不可能始终保证信息百分之百的安全。您需要了解，您接入我们服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。为防止安全事故的发生，我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，力求将损失最小化，并按照规定向有关主管部门报告。
      </p>
      <p>
        &nbsp;&nbsp;2.个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，由公司多个部门组成应急响应小组，在最短时间内追溯原因并减少损失。
      </p>
      <p>
        &nbsp;&nbsp;3.如果一旦发生安全事件影响到您的个人信息安全的，我们将及时通过您预留的邮箱、电话、系统推送、公告等方式通知您：安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施，并告知您降低或防范相关风险的建议等信息。
      </p>
      <p>
        &nbsp;&nbsp;4.必要时，我们将根据适用的法律、法规通知主管部门。同时，我们会及时处置系统漏洞、网络攻击、病毒侵袭及网络侵入等安全风险。在发生危害网络安全的事件时，我们会按照网络安全事件应急预案，及时采取相应的补救措施，并按照规定向有关主管部门报告，紧密配合政府机关的工作。
      </p>

      <p>&nbsp;&nbsp;六、我们如何使用COOKIES等自动收集工具</p>
      <p>
        &nbsp;&nbsp;当您使用闪借花平台时，我们使用COOKIES和其他类似技术来记录信息，包括您的用户ID以及与我们服务相关的日志，此等技术帮助我们更好地了解用户的行为，提供更好的用户体验。
      </p>
      <p>
        &nbsp;&nbsp;请您理解，我们平台中的许多COOKIES起着至关重要的作用：例如，当您登录我们账号时，COOKIES记录着您的账号信息，使您省去重复输入注册信息等步骤，或帮助您判断您的账户安全。其它的COOKIES帮助我们了解您使用我们平台的方式，从而使我们改进线上工作。对此我们使用相关的分析工具分析我们平台的加载时间、访客使用的方式，以及我们平台的访客查看最多的信息。它们还帮助我们发现我们平台中没有达到预期效果的部分，促使我们采取修复措施，为您打造更好用的平台。
      </p>

      <p>&nbsp;&nbsp;七、您对个人信息的控制</p>
      <p>
        &nbsp;&nbsp;我们非常尊重您对个人信息的控制权限，并为您提供了控制您个人信息的方法。您有权利查询、更正、管理、删除自己的个人信息并保护自己的隐私，您可以通过如下方式完成：
      </p>
      <p>
        &nbsp;&nbsp;
        在相关技术设置允许的情况下，登录您的平台注册账户访问、更新、管理或删除，但您在使用我们的服务过程中留存的交易记录（包括但不限于借款还款信息）除外；
      </p>
      <p>&nbsp;&nbsp;（一）访问和更正您的个人信息</p>
      <p>
        &nbsp;&nbsp;您有权访问您的个人信息，法律法规规定的例外情况除外。您可以在闪借花APP自行访问和更正您的个人信息：
      </p>
      <p>&nbsp;&nbsp;1.您可以在【闪借花】首页访问您的额度；</p>
      <p>
        &nbsp;&nbsp;您可以在【闪借花→我的】访问您的借款记录、还款计划等信息；
      </p>
      <p>
        &nbsp;&nbsp;2.您可以在【我的→头像】访问和修改您的头像、登录手机号、详细联系地址；
      </p>
      <p>
        &nbsp;&nbsp;3、您可以在【我的→设置】访问和修改和重置您的登录密码、登录手机号；
      </p>
      <p>&nbsp;&nbsp;4.你可以在【我的→银行卡管理】添加或解除银行卡绑定；</p>
      <p>
        &nbsp;&nbsp;当您仅有一张绑定的银行卡时，为避免账户款项无法支付至您的银行账户或实现自动还款服务，您无法在本应用自行操作解除银行卡绑定，如您需解除您唯一绑定的银行卡，请您通过我们的微信公众号“小省在线”（shengbei01）联系在线客服进行确认和处理。
      </p>
      <p>
        &nbsp;&nbsp;如果您无法通过上述方式访问该等个人信息，您可以通过在线客服和人工客服与我们联系。针对您在使用闪借花产品和／或服务过程中产生的其他个人信息，如您需要我们协助提供的，我们会在合理范围内尽量满足您的需求。但为保护您的信息安全和我们的合法权益，未经我们同意，您不得采用其他方式或者授权第三方访问或收集平台保存的您的个人信息。
      </p>
      <p>&nbsp;&nbsp;（二）删除您的个人信息</p>
      <p>
        &nbsp;&nbsp;在以下情形中，您可以向我们提出删除个人信息的请求，我们会响应您的删除请求。您可以通过平台提供的联系方式随时与我们联系：
      </p>
      <p>
        &nbsp;&nbsp;1.如果我们违反法律法规或与您的约定收集、使用个人信息的；
      </p>
      <p>
        &nbsp;&nbsp;2.如果我们违反法律法规或与您的约定与第三方共享或转让您的个人信息，我们将立即停止共享、转让行为，并通知第三方及时删除；
      </p>
      <p>
        &nbsp;&nbsp;3.如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，我们将立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息；
      </p>
      <p>
        &nbsp;&nbsp;4.如果您不再使用我们的产品或服务，或您注销了账号，或我们终止服务及运营。
      </p>
      <p>
        &nbsp;&nbsp;您可以联系我们要求删除您的个人信息。以上删除请求一旦被响应，除法律法规另有规定要求保留的信息外，您的个人信息将被及时删除。当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </p>
      <p>&nbsp;&nbsp;(三)改变您授权同意的范围</p>
      <p>
        &nbsp;&nbsp;请您理解，每个业务功能往往需要开启部分权限并收集必要的个人信息才能得以实现，对于您个人信息的收集、使用以及设备权限的管理，您可以通过设备系统权限设置或联系在线客服要求撤回同意或删除，或通过以下方式自行操作授权：
      </p>
      <p>
        &nbsp;&nbsp;1.您可以通过【我的→设置→账号授权管理】，管理您的账号授权。
      </p>
      <p>
        &nbsp;&nbsp;2.您可以通过【我的→设置→隐私管理】，查看和管理您的定位、相机、存储空间、通讯录、APP通知等设备权限。
      </p>
      <p>
        &nbsp;&nbsp;当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。
      </p>
      <p>&nbsp;&nbsp;（四）注销账户</p>
      <p>
        &nbsp;&nbsp;您可以通过点击如下具体路径：
        【我的→帮助中心→如何注销账户】
        根据页面提示联系在线客服进行操作。您注销上述账户的行为是不可逆行为，并且我们将停止为您提供产品和
        /
        或服务，不再收集您的个人信息，并依据您的要求删除与您账户相关的个人信息或作匿名化处理，但法律法规或监管机构对用户信息存储时间另有规定的除外。为了保护您或他人的合法权益，我们需要在您提交注销申请时验证您的身份，结合您对闪借花各产品和
        / 或服务的使用情况 ( 例如是否存在在途借款、是否存在未决争议及纠纷 )
        判断是否支持您的注销请求。
      </p>
      <p>
        &nbsp;&nbsp;对于您的个人信息访问更正删除等问题，您随时可以通过“联系方式”部分公布的方式，联系我们，我们非常愿意为您提供可能的协助。
      </p>
      <p>&nbsp;&nbsp;（五）获取个人信息副本</p>
      <p>
        &nbsp;&nbsp;根据法律法规及相关国家标准，您有权获取您的个人基本资料、个人身份信息副本，您可以通过在线客服等方式联系我们。
      </p>
      <p>&nbsp;&nbsp;(六)拒绝商业推送</p>
      <p>
        &nbsp;&nbsp;如果您不想闪借花通过短信、站内信、电子邮件等方式，合理地向您发送商业性服务信息、平台最新的有关管理、产品内容及其他平台认为有必要告知注册会员的服务通知，您可以通过【我的→设置→隐私管理→APP通知】来关闭站内信推送，也可以通过在线客服联系我们关闭此功能。
      </p>
      <p>&nbsp;&nbsp;(七)提前通知产品和／或服务停止运营</p>
      <p>
        &nbsp;&nbsp;我们愿一直陪伴您，若因特殊原因导致闪借花产品和／或服务被迫停止运营，我们将按照法律法规要求在产品和/或服务的主页面或站内信或向您发送电子邮件或公告等其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或作匿名化处理。
      </p>
      <p>&nbsp;&nbsp;（八）响应您的上述请求</p>
      <p>
        &nbsp;&nbsp;为保障安全，我们可能会要求先验证您的身份，再处理您的请求。您可能需要提供书面请求，或以其他方式证明您的身份。对于您提出的本节项下的所有请求，我们原则上将于收到您的请求并在验证您用户身份后的15日内进行反馈。
      </p>
      <p>
        &nbsp;&nbsp;对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段(例如，需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
      </p>
      <p>
        &nbsp;&nbsp;在以下情形中，按照法律法规要求，我们将无法响应您的更新、管理或删除等请求：
      </p>
      <p>&nbsp;&nbsp;1.与我们履行法律法规规定的义务相关的；</p>
      <p>&nbsp;&nbsp;2.与国家安全、国防安全直接相关的；</p>
      <p>&nbsp;&nbsp;3.与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>&nbsp;&nbsp;4.与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
      <p>
        &nbsp;&nbsp;5.我们有充分证据表明您存在主观恶意或滥用权利的(如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围)；
      </p>
      <p>
        &nbsp;&nbsp;6.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
      </p>
      <p>
        &nbsp;&nbsp;7.响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；
      </p>
      <p>&nbsp;&nbsp;8.涉及商业秘密的；</p>
      <p>&nbsp;&nbsp;9.法律法规规定的其他情形。</p>

      <p>&nbsp;&nbsp;八、未成年人个人信息特别约定</p>
      <p>
        &nbsp;&nbsp;我们非常重视未成年人的个人信息保护，我们的网站、应用及相关产品或服务均不以未成年人为目标客户。如果您是18周岁以下的未成年人，我们将不会对您提供服务(但在未身份认证情况下进行的有限浏览情形除外)。我们不会故意收集未成年人的任何个人信息，除非您主动提供且因获取某些产品或服务必须提供某些未成年人的必要信息外（例如您提供的房产信息可能包括未成年人信息）。
      </p>
      <p>
        &nbsp;&nbsp;如果您是未成年人，请不要尝试注册闪借花平台，或不要以其它方式向我们提供任何您的个人信息。我们提供的部分产品和/或服务项下可能会收集未成年人的姓名、身份证件信息。我们只会在法律允许、父母或监护人明确同意的情况下收集上述未成年人的信息。同时，我们承诺，我们将根据国家相关法律法规及本政策的规定确保未成年人用户信息的保密性及安全性如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，我们会采取必要措施主动及时地删除相关信息。
      </p>
      <p>
        &nbsp;&nbsp;若您是未成年人的监护人，当您注意到您所监护的未成年人存在使用平台上的产品和/或服务或自行向我们提供的个人信息的情况时，请您及时与我们联系。
      </p>

      <p>&nbsp;&nbsp;九、本政策的适用及更新</p>
      <p>
        &nbsp;&nbsp;我们所有服务均适用本政策，除非相关服务已有独立的隐私权政策或相应的用户服务协议当中存在特殊约定。为了给您提供更好的服务以及业务发展的需要，我们保留适时更新本政策的权利。未经您明确同意，我们不会削减您在本政策项下应享有的权利。发生下列重大变化情形时，我们会适时对本政策进行更新：
      </p>
      <p>
        &nbsp;&nbsp;（一）我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；
      </p>
      <p>
        &nbsp;&nbsp;（二）收集、存储、使用个人信息的范围、目的、规则发生变化；
      </p>
      <p>&nbsp;&nbsp;（三）对外提供个人信息的对象、范围、目的发生变化；</p>
      <p>&nbsp;&nbsp;（四）您访问和管理个人信息的方式发生变化；</p>
      <p>&nbsp;&nbsp;（五）数据安全能力、信息安全风险发生变化；</p>
      <p>
        &nbsp;&nbsp;（六）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；
      </p>
      <p>&nbsp;&nbsp;（七）为您提供服务/产品的业务功能增加或减少；</p>
      <p>&nbsp;&nbsp;（八）其他可能对您的个人信息权益产生重大影响的变化。</p>
      <p>
        &nbsp;&nbsp;由于闪借花的用户较多，如本政策发生更新，我们将以APP推送通知、弹窗提示、发送邮件/短消息或者在闪借花官方网站及微信公众号发布公告、在页面显著位置提示的等合理方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用闪借花服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。若您不同意修改后的隐私权政策，您有权并应立即停止使用闪借花产品和
        / 或服务。
      </p>
      <p>
        &nbsp;&nbsp;您可以在闪借花APP通过【我的→关于闪借花→闪借花服务协议→闪借花隐私权政策】中查看本政策。我们还会将旧版本的隐私权政策进行存档，供您查阅。本次隐私政策更新日期为2020年9月1日，如您认为更新的《隐私权政策》对您更为有利，我们同意双方适用新的《隐私权政策》内容。
      </p>
      <p>&nbsp;&nbsp;本版本《隐私政策》更新内容主要如下：</p>
      <p>
        &nbsp;&nbsp;1.增加对各项业务功能收集和使用的个人信息以及共享的个人信息逐项列举；
      </p>
      <p>
        &nbsp;&nbsp;2.完善我们如何保护个人信息采取的技术措施与数据安全措施及安全事件处理措施；
      </p>
      <p>
        &nbsp;&nbsp;3.
        明确用户个人信息更正、删除、注销、撤回授权、变更授权、获取个人信息副本等自助途径；
      </p>
      <p>&nbsp;&nbsp;4.其他相关修订。</p>

      <p>&nbsp;&nbsp;十、我们的联系方式</p>
      <p>
        &nbsp;&nbsp;我们设立了专职个人信息保护负责人和个人信息保护工作机构，个人信息保护负责人的联系方式为shengbei-help@szyixuan.top。如果您对本政策有任何疑问、意见或建议，或您在使用我们产品或服务时，就您的个人信息的收集、使用、共享、访问、删除、更正等相关事宜有任何意见、建议、投诉或举报，或您在使用产品或服务时遇到哪些问题，请根据如下方式联系我们，我们会在15个工作日内及时答复您。
      </p>
      <p>
        &nbsp;&nbsp;1.您可以通过闪借花官网（www.szyixuan.top），客服电话（4000710109）、闪借花官网微信--小省在线(ID：shengbei01)在线客服方式与我们联系。
      </p>
      <p>
        &nbsp;&nbsp;2.我们的办公地址：深圳市龙岗区吉华街道翠湖社区西环路金沙大厦601。
      </p>
      <p>
        &nbsp;&nbsp;为保障我们高效处理您的问题并及时向您反馈，部分情况下我们可能会要求您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份后处理您的请求。
      </p>
      <p>
        &nbsp;&nbsp;如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：向被告所在地人民法院提起诉讼，您也可向有关政府部门或者行业协会进行反映
        。
      </p>

      <p>&nbsp;&nbsp;十一、本政策中关键词说明</p>
      <p>&nbsp;&nbsp;1.我们，指我司。</p>
      <p>
        &nbsp;&nbsp;2.您，指使用我们的产品和服务的注册用户以及收费服务的购买方。
      </p>
      <p>
        &nbsp;&nbsp;3.闪借花平台或闪借花，指由我司运营闪借花移动应用APP、网站www.szyixuan.top、闪借花-官微、闪借花官网微信公众号--小省在线(ID：shengbei01)、闪借花H5页面等网络媒介平台。
      </p>
      <p>
        &nbsp;&nbsp;4.合作金融机构：与我们建立合作关系的银行业金融机构或消费金融公司、信托公司、小额贷款公司等金融机构及其关联公司，并在本应用开通授信额度申请渠道、小额资金借款渠道的具有相关金融资质、且其金融产品符合法律法规、金融监管要求的机构。
      </p>
      <p>
        &nbsp;&nbsp;5.合作担保公司：指与我们建立合作关系的具有从事互联网担保业务资质的、愿意为本应用的用户向第三方金融机构提供第三方增信措施的担保公司，目前合作的担保公司为湖南汇鑫融资担保有限公司。
      </p>
      <p>
        &nbsp;&nbsp;6.个人信息，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。
      </p>
      <p>&nbsp;&nbsp;7.个人信息主体，指个人信息所标识的自然人。</p>
      <p>
        &nbsp;&nbsp;8.个人敏感信息，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人健康生理信息、个人生物识别信息、个人身份信息、网络身份标识信息等。
        为了帮助您更好地理解个人敏感信息类型，我们依据 GB/T 35273-2017
        《信息安全技术
        个人信息安全规范》将个人敏感信息示例如下，但不代表我们所收集的全部个人敏感信息：
      </p>
      <p>&nbsp;&nbsp;个人财产信息：</p>
      <p>
        &nbsp;&nbsp;银行账号、鉴别信息 ( 口令 ) 、存款信息 (
        包括资金数量、支付收款记录等 )
        、房产信息、信贷记录、征信信息、交易和消费记录、流水记录等，以及虚拟货币、虚拟交易、游戏类兑换码等虚拟财产信息
      </p>
      <p>&nbsp;&nbsp;个人健康生理信息：</p>
      <p>
        &nbsp;&nbsp;个人因生病医治等产生的相关记录、如病症、住院志、医嘱单、检验报告、手术及麻醉记录、护理记录、用药记录、药物食物过敏信息、生育信息、以往病史、诊治情况、家族病史、现病史、传染病史等，以及与个人身体健康状况产生的相关信息
      </p>
      <p>&nbsp;&nbsp;个人生物识别信息：</p>
      <p>
        &nbsp;&nbsp;个人基因、指纹、声纹、掌纹、耳廓，虹膜、面部识别特征等
      </p>
      <p>&nbsp;&nbsp;个人身份信息：</p>
      <p>&nbsp;&nbsp;身份证、军官证、护照、驾照、工作证、社保卡、居住证等</p>
      <p>&nbsp;&nbsp;网络身份标识信息</p>
      <p>
        &nbsp;&nbsp;系统账号、邮箱地址及与前述有关的密码、口令、口令保护答案、用户个人数字证书等
      </p>
      <p>&nbsp;&nbsp;其他信息：</p>
      <p>
        &nbsp;&nbsp;个人电话号码、性取向、婚史、宗教信仰、未公开的违反犯罪记录、通信记录和内容、行踪轨迹、网页浏览记录、住宿信息、精准定位信息等
      </p>
      <p>
        &nbsp;&nbsp;9.
        设备信息，设备信息可分为设备属性信息、设备位置信息与设备连接信息。设备属性信息是指用户使用终端设备访问闪借花平台时的设备品牌及型号、设备屏幕规格、操作系统版本、设备设置、唯一设备识别符等软硬件特征。
        设备位置信息是指通过用户使用的终端设备获取用户访问闪借花平台时所处的地理位置信息，如通过
        GPS 、蓝牙或 WiFi 信号获得的位置信息。
        设备连接信息是指用户使用终端设备访问闪借花平台所显示的 IP
        地址、浏览器的类型、电信运营商、适用的语言、访问日期和时间信息。
      </p>
      <p>
        &nbsp;&nbsp;10.去标识化，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。
      </p>
      <p>
        &nbsp;&nbsp;11.匿名化，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
      </p>

      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'PrivacyAgreement',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang='less'>
  #agreement {
    padding: 20px;
    color: #3e4a59;
    font-family: '微软雅黑,PingFangSC-Medium,PingFangSC';
  }
</style>
