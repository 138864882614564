<template>
  <div class="product-container">
      <van-nav-bar
      class="nav-bar"
      :title="product.name"
      left-arrow
      right-text="分享"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div style="width: 100%;height: 500px;">
        <iframe style="width: 100%; height: 800px;" :src="product.url" allowfullscreen="allowfullscreen" frameborder="0" data-id="1"></iframe>
    </div>
  </div>
</template>

<script>
import { recordClick } from '@/api/user'

export default {
  data () {
    return {
      product: {}
    }
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    onClickRight() {
      window.open(this.product.url)
    }
  },
  async mounted () {
    this.product = this.$route.query
    if (!sessionStorage.getItem('hmx-token')) {
      const source = this.product.source
      const token = this.product.token
      sessionStorage.setItem('hmx-token', token)
      localStorage.setItem('hmx-source', source)
    }
    await recordClick({ productCode: this.product.code })
  }
}
</script>

<style scoped lang="less">
/deep/.nav-bar {
  background-color: #4f7adc;
  .van-icon,
  .van-nav-bar__title {
    color: #fff;
  }
  .van-nav-bar__text {
    color: #fff;
  }
}

object {
  border: none;
  width: 100%;
  // margin-top: 100px;
  height: calc(100vh - 100px);
}
</style>
