<template>
  <div id="app">
    <router-view />
    <!-- 微信浏览器引导遮罩 -->
    <div class="wechat-guide" v-if="showWechatGuide">
      <img src="@/assets/diver.png" alt="请使用系统浏览器打开">
    </div>
  </div>
</template>

<script>
import { isWeixinBrowser } from '@/utils/tools'

export default {
  name: 'App',
  data() {
    return {
      showWechatGuide: false
    }
  },
  created() {
    // 使用 import 的工具方法检测微信浏览器
    this.showWechatGuide = isWeixinBrowser()
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.wechat-guide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
}
</style>
