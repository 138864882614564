<template>
  <div class="layout-container">
    <router-view />
    <van-tabbar v-model="active" inactive-color="#999999" route>
      <van-tabbar-item :to="`/home/${path}`" class="tabbar-box">
        <span class="text" :class="{ active: !this.active }">首页</span>
        <img slot="icon" class="img" :src="homeIcon()" />
      </van-tabbar-item>
      <van-tabbar-item :to="`/my/${path}`">
        <span class="text" :class="{ active: this.active === 1 }">我的</span>
        <img slot="icon" class="img" :src="myIcon()" />
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  data() {
    return {
      path: '',
      active: 0,
      icon: {
        active: require('@/assets/home.webp'),
        inactive: require('@/assets/home-active.webp')
      },
      icon1: {
        active: require('@/assets/my.webp'),
        inactive: require('@/assets/my-active.webp')
      }
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.path = localStorage.getItem('source')
        return (this.active = val.path.includes('my') ? 1 : 0)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    homeIcon() {
      return this.active ? this.icon.active : this.icon.inactive
    },
    myIcon() {
      return !this.active ? this.icon1.active : this.icon1.inactive
    }
  }
}
</script>

<style scoped lang="less">
.layout-container {
  /deep/ .tabbar-box {
    .text {
      font-size: 24px;
    }

    .img {
      width: 43px;
      height: 44px;
    }
  }

  .active {
    color: #517cdd;
  }
}
</style>
