import CryptoJS from 'crypto-js'
// const CryptoJS = require('crypto-js')

/**
 * 加密方法
 */
export function encrypt (data) {
  const SECRET_KEY = '0CoJUm6Qyw8W8jud'
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }

  const ciphertext = CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(SECRET_KEY),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
  ).toString()

  return ciphertext
}
