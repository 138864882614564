<template>
  <div class="splash-container">
    <!-- logo -->
    <!-- <img class="logo" src="@/assets/logo.png" /> -->
    <div class="content">
      <div class="title-wrap">
        <div class="left"></div>
        <div class="center">产品详情</div>
        <div class="right"></div>
      </div>
      <div class="desc-wrap">
        <div>
          <span>到账时间</span>
          <span>机审最快<strong>10</strong>分钟通过</span>
        </div>
        <div>
          <span>申请条件</span>
          <span>凭身份证和手机号可申请</span>
        </div>
        <div>
          <span>产品期限</span>
          <span
            ><strong>3</strong
            >、<strong>6</strong>、<strong>12</strong>期灵活选择</span
          >
        </div>
      </div>

      <van-cell-group :border="false" class="ipt-content">
        <van-field
          class="ipt"
          maxlength="11"
          type="tel"
          v-model="phoneNo"
          placeholder="请输入手机号"
        >
        </van-field>
      </van-cell-group>

      <van-button class="get-limit" @click="getLimit">立即提现</van-button>

      <div class="show-agree">
        <van-checkbox v-model="checked" shape="round"></van-checkbox>
        <div>
          我已阅读并同意<span @click="showUserAgreement = true">《用户注册协议》</span>和<span @click="showPrivacyAgreement = true">《隐私政策》</span>
        </div>
      </div>
      <div class="data-wrap">
        <div>
          <img src="@/assets/s1.png" />
          <span>最快十分钟到账</span>
        </div>
        <div>
          <img src="@/assets/s2.png" />
          <span>日息低至0.02%</span>
        </div>
        <div>
          <img src="@/assets/s3.png" />
          <span>正规放款机构</span>
        </div>
      </div>
    </div>
    <!-- 使用协议 -->
    <UserAgreement :isVisible.sync="showUserAgreement"></UserAgreement>
    <!-- 隐私政策 -->
    <PrivacyAgreement :isVisible.sync="showPrivacyAgreement"></PrivacyAgreement>
    <div id="cover" v-show="showCoverFlag">
      <img src="@/assets/diver.png">
    </div>
  </div>
</template>

<script>
import { login, channelLog, viewSwitch, checkIsExist } from '@/api/user'
import { encrypt } from '@/utils/crypto'
import PrivacyAgreement from './PrivacyAgreement'
import UserAgreement from './UserAgreement'
export default {
  name: 'Splash',
  components: {
    PrivacyAgreement,
    UserAgreement
  },
  data () {
    return {
      phoneNo: '',
      checked: true,
      showUserAgreement: false,
      showPrivacyAgreement: false,
      showCoverFlag: false
    }
  },
  props: {
    source: {
      type: String,
      default: localStorage.getItem('hmx-source') || 'unknow'
    }
  },
  async mounted () {
    this.checkUA()
    if (this.source) {
      localStorage.setItem('hmx-source', this.source)
      await channelLog()
    }
    const token = sessionStorage.getItem('hmx-token') || ''
    if (token !== '') {
      const res1 = await viewSwitch()
      if (res1.data) {
        const res2 = await checkIsExist()
        if (res2.data) {
          this.go2Page('/check')
        } else {
          this.go2Page('/auth')
        }
      } else {
        this.go2Page('/home')
      }
    }
  },
  methods: {
    checkUA() {
      const ua = window.navigator.userAgent
      if (ua.indexOf('MicroMessenger') > 0) {
        this.showCoverFlag = true
      }
    },
    async getLimit () {
      if (!this.checked) {
        return this.$toast('请阅读并同意协议哦')
      }
      const phoneNo = this.phoneNo
      if (!phoneNo) {
        return this.$toast('请输入手机号')
      }
      if (phoneNo.length !== 11) {
        return this.$toast('手机号格式不正确')
      }
      const res = await login(encrypt({ phoneNo: this.phoneNo }))
      if (res.code === 200) {
        this.$toast('登录成功')
        sessionStorage.setItem('hmx-token', res.data)
        localStorage.setItem('hmx-mobile', this.phoneNo)

        const res1 = await viewSwitch()
        if (res1.data) {
          const res2 = await checkIsExist()
          if (res2.data) {
            this.go2Page('/check')
          } else {
            this.go2Page('/auth')
          }
        } else {
          this.go2Page(`/home/${this.source}`)
        }
      } else {
        this.$toast(res.message)
      }
    },
    go2Page (path) {
      setTimeout(() => {
        this.$router.push(path)
      }, 500)
    }
  }
}
</script>

<style scoped lang="less">
.splash-container {
  // position: relative;
  background: url('~@/assets/splash.png') no-repeat center / cover;
  height: 100vh;
  // overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .logo {
    width: 60px;
    border-radius: 10px;
    margin-top: 30px;
    margin-left: 20px;
  }

  .content {
    width: 90%;
    //height: 730px;
    background: #ffffff;
    border-radius: 20px;
    opacity: 1;
    position: absolute;
    bottom: 20px;
    transform: translate(5%);
    padding: 30px 30px 10px;
    box-sizing: border-box;
    .title-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      .left {
        width: 72px;
        height: 12px;
        background: linear-gradient(270deg, #3051e6 0%, #ebeeff 100%);
      }
      .right {
        width: 72px;
        height: 12px;
        background: linear-gradient(270deg, #ebeeff 0%, #3051e6 100%);
      }
      .center {
        font-size: 34px;
        font-family: PingFang SC-Medium, PingFang SC;
        color: #333333;
        line-height: 69px;
        margin: 0 10px;
      }
    }

    .desc-wrap {
      padding: 20px;
      box-sizing: border-box;
      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        span:nth-child(1) {
          font-size: 30px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        span:nth-child(2) {
          font-size: 28px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }

        strong {
          color: #ff0000;
        }
      }
    }

    .ipt-content {
      opacity: 1;
      .ipt {
        // width: 588px;
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 38px;
      }
    }

    .get-limit {
      // width: 588px;
      width: 100%;
      height: 88px;
      background: #3c59e8;
      border-radius: 100px 100px 100px 100px;
      font-size: 28px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-top: 20px;
    }

    .show-agree {
      display: flex;
      margin-top: 20px;
      align-items: center;
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 10px;
      .van-checkbox {
        margin-right: 10px;
        /deep/.van-checkbox__icon--checked .van-icon {
          background-color: #4c69f2;
          border-color: #4c69f2;
        }
      }
      span {
        color: #d78e6e;
      }
    }

    .data-wrap {
      display: flex;
      justify-content: space-around;
      padding: 10px;
      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 48px;
          margin-bottom: 10px;
        }
        span {
          font-size: 24px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
        }
      }
    }

    .company {
      text-align: center;
      color: #333;
    }
  }

  #agreement1,
  #agreement2 {
    padding: 20px;
    color: #3e4a59;
    font-family: '微软雅黑,PingFangSC-Medium,PingFangSC';
  }

  #cover {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
        z-index: 999;
        text-align: right;
    }
    #cover img {
        width: 70%;
        padding: 10px;
    }
}
</style>
