import axios from 'axios'
import router from '@/router'

const request = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'https://yx.ahfenghong.com/app' : '/app'
})

request.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json'
  // 统一添加 source
  config.headers.source = localStorage.getItem('source') || ''
  // 统一添加 token
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.token = token
  }
  return config
})

request.interceptors.response.use((res) => {
  const source = localStorage.getItem('source') || 'unknow'
  if (res.data.code === 10011 || res.data.code === 10010) {
    localStorage.clear()
    localStorage.setItem('source', source)
    router.push('/login/' + source)
    
  }else{
    if(res.data.code != 200){
      throw new Error(res.data.message)
    }
  }
  return res.data
})

export default request
