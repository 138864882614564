import request from '@/utils/request'

export const getChannelConfig = () => {
  return request({
    method: 'GET',
    url: '/channelConfig'
  })
}

export const login = (data) => {
  return request({
    method: 'POST',
    url: '/auth/login',
    data
  })
}
export const userExists = (data) => {
  return request({
    method: 'POST',
    url: '/auth/exists',
    data
  })
}
export const sendSms = (data) => {
  return request({
    method: 'POST',
    url: '/auth/send-sms',
    data
  })
}
export const getCaptcha = (data) => {
  return request({
    method: 'GET',
    url: '/captcha/gen',
    data
  })
}

export const checkCaptcha = (data) => {
  return request({
    method: 'POST',
    url: '/captcha/verify',
    data
  })
}

export const channelLog = () => {
  return request({
    method: 'POST',
    url: '/channel/log'
  })
}

export const getRecommend = () => {
  return request({
    method: 'GET',
    url: '/product/recommend'
  })
}

export const getRecommendList = () => {
  return request({
    method: 'GET',
    url: '/product/recommendList'
  })
}

export const recordClick = data => {
  return request({
    method: 'POST',
    url: '/product/click',
    data
  })
}

export const getProductList = () => {
  return request({
    method: 'GET',
    url: '/product/list'
  })
}

// /app/switchFlag 判断渠道开关
export const viewSwitch = () => {
  return request({
    method: 'GET',
    url: '/switchFlag'
  })
}

export const checkIsExist = () => {
  return request({
    method: 'GET',
    url: '/user-loan-info/exists'
  })
}

export const saveLoanInfo = data => {
  return request({
    method: 'POST',
    url: '/user-loan-info/save',
    data
  })
}
