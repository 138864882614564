<template>
  <van-popup
    position="bottom"
    v-model='isVisible'
    :style="{ height: '80%' }"
    closeable
    @close="$emit('update:isVisible', false)">
    <div id="agreement">
      <p align="center">
        <span class="tit">用户注册协议</span>
      </p>
      <p>一、总则</p>
      <p>
        1.1
        用户应当同意本协议的条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中勾选"已阅读并同意"即表示用户达成协议，完全接受本协议项下的全部条款。
      </p>
      <p>
        1.2
        用户注册成功后，系统将给予每个用户一个用户账号，该用户账号由用户负责保管；用户应当对以其用户账号进行的所有活动和事件负法律责任。
      </p>
      <p>
        1.3
        当用户使用软件的各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及在该单项服务中发出的各类公告的同意。
      </p>
      <p>1.4 您在使用相关服务时，应关注并遵守其所适用的相关条款。</p>
      <p>
        您在使用系统提供的各项服务之前，应仔细阅读本服务协议。如您不同意本服务协议及/或随时对其的修改，您可以主动取消系统提供的服务；您一旦使用系统服务，即视为您已了解并完全同意本服务协议各项内容，包括系统对服务协议随时所做的任何修改。
      </p>
      <p>&nbsp;</p>
      <p>二、注册信息和隐私保护</p>
      <p>
        2.1
        系统账号（即系统用户ID）的所有权归系统，用户完成注册申请手续后，获得系统账号的使用权。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实而引起的问题，并对问题发生所带来的后果，系统不负任何责任。
      </p>
      <p>
        2.2
        用户不应将其账号转让、出售或出借予他人使用，如果系统发现使用者并非账号注册者本人，系统有权停止继续服务。如用户发现其账号遭他人非法使用，应立即通知系统。因黑客行为或用户违反本协议规定导致账号遭他人非法使用的，由用户本人承担因此导致的损失和一切法律责任，系统不承担任何责任。
      </p>
      <p>
        2.3
        系统的隐私政策说明了系统如何收集和使用用户信息。您保证已经充分了解并同意系统可以据此处理用户信息。
      </p>
      <p>&nbsp;</p>
      <p>三、使用规则</p>
      <p>
        3.1
        用户在使用系统服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为
        <span style="font-size: 21px; font-family: 'MS Mincho'">∶</span>
      </p>
      <p>
        （1）上载、展示、张贴、传播或以其他方式传送含有下列内容之一的信息：
      </p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;1）反对宪法所确定的基本原则的；</p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
      </p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3）损害国家荣誉和利益的；</p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;4）煽动民族仇恨、民族歧视、破坏民族团结的；
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;5）破坏国家宗教政策，宣扬邪教和封建迷信的；
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;6）散布谣言，扰乱社会秩序，破坏社会稳定的；
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;8）侮辱或者诽谤他人，侵害他人合法权利的；
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;9）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其他道德上令人反感的内容；
      </p>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;10）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其他内容的；
      </p>
      <p>（2）不得为任何非法目的而使用网络服务系统；</p>
      <p>（3）不利系统服务从事以下活动：</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        4）故意制作、传播计算机病毒等破坏性程序的；
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        5）其他危害计算机信息网络安全的行为。
      </p>
      <p>
        3.2
        用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿系统与合作公司、关联公司，并使之免受损害。对此，系统有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收系统账号、追究法律责任等措施。对恶意注册系统账号或利用系统账号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，系统有权回收其账号。
      </p>
      <p>
        3.3
        用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其他商业目的。
      </p>
      <p>&nbsp;</p>
      <p>四、服务内容</p>
      <p>
        4.1
        除非本服务协议另有其他明示规定，系统所推出的新产品、新功能、新服务，均受到本服务协议之规范。
      </p>
      <p>
        4.2
        为使用本服务，您必须能够自行经有法律资格对您提供互联网接入服务的第三方，进入国际互联网，并应自行支付相关服务费用。此外，您必须自行配备及负责与国际联网连线所需之一切必要装备，包括计算机、数据机或其他存取装置。
      </p>
      <p>
        4.3
        鉴于网络服务的特殊性，用户同意系统有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。系统不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
      </p>
      <p>
        4.4
        系统需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务（包括收费网络服务）在合理时间内的中断，系统无需为此承担任何责任。系统保留不经事先通知为维修保养、升级或其他目的暂停本服务任何部分的权利。
      </p>
      <p>
        4.5
        本服务或第三人可提供与其他国际互联网上之网站或资源之链接。由于系统无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，系统不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其他资料，系统亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，系统不承担任何责任。
      </p>
      <p>
        4.6
        用户明确同意其使用系统网络服务所存在的风险将完全由其自己承担。用户理解并接受下载或通过系统服务取得的任何信息资料取决于用户自己，并由其承担系统受损、资料丢失以及其他任何风险。系统对在服务网上得到的任何商品购物服务、交易进程、招聘信息等，都不作担保。
      </p>
      <p>
        4.7
        用户须知：系统提供的各种挖掘推送服务中，推送给用户曾经访问过的网站或资源之链接是基于机器算法自动推出，系统不对其内容的有效性、安全性、合法性等做任何担保。
      </p>
      <p>4.8 6个月未登陆的账号，系统保留关闭的权利。</p>
      <p>
        4.9
        系统有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，系统对用户和任何第三人均无需承担任何责任。
      </p>
      <p>4.10 终止服务</p>
      <p>
        您同意系统得基于其自行之考虑，因任何理由，包含但不限于长时间未使用，或系统认为您已经违反本服务协议的文字及精神，终止您的密码、账号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，系统可立即关闭或删除您的账号及您账号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的账号及相关信息和文件被关闭或删除，系统对您或任何第三人均不承担任何责任。
      </p>
      <p>&nbsp;</p>
      <p>五、知识产权和其他合法权益（包括但不限于名誉权、商誉权）</p>
      <p>5.1 用户专属权利</p>
      <p>
        系统尊重他人知识产权和合法权益，呼吁用户也要同样尊重知识产权和他人合法权益。若您认为您的知识产权或其他合法权益被侵犯，请按照以下说明提供资料
        <span style="font-size: 21px; font-family: 'MS Mincho'">∶</span>
      </p>
      <p>
        请注意：如果权利通知的陈述失实，权利通知提交者将承担对由此造成的全部法律责任（包括但不限于赔偿各种费用及律师费）。如果上述个人或单位不确定网络上可获取的资料是否侵犯了其知识产权和其他合法权益，系统建议该个人或单位首先咨询专业人士。
      </p>
      <p>
        为了系统有效处理上述个人或单位的权利通知，请使用以下格式（包括各条款的序号）：
      </p>
      <p>
        1、权利人对涉嫌侵权内容拥有知识产权或其他合法权益和/或依法可以行使知识产权或其他合法权益的权属证明；
      </p>
      <p>
        2、请充分、明确地描述被侵犯了知识产权或其他合法权益的情况并请提供涉嫌侵权的第三方网址（如果有）；
      </p>
      <p>3、请指明涉嫌侵权网页的哪些内容侵犯了第2项中列明的权利；</p>
      <p>
        4、请提供权利人具体的联络信息，包括姓名、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、通信地址、电话号码、传真和电子邮件；
      </p>
      <p>
        5、请提供涉嫌侵权内容在信息网络上的位置（如指明您举报的含有侵权内容的出处，即：指网页地址或网页内的位置）以便我们与您举报的含有侵权内容的网页的所有权人/管理人联系；
      </p>
      <p>
        6、请在权利通知中加入如下关于通知内容真实性的声明：“我保证，本通知中所述信息是充分、真实、准确的，如果本权利通知内容不完全属实，本人将承担由此产生的一切法律责任。”
      </p>
      <p>
        5.2
        系统拥有本软件内所有资料的版权。任何被授权的浏览、复制、打印和传播属于本软件内的资料必须符合以下条件：
      </p>
      <p>（1）所有的资料和图象均以获得信息为目的；</p>
      <p>（2）所有的资料和图象均不得用于商业目的；</p>
      <p>（3）所有的资料、图象及其任何部分都必须包括此版权声明；</p>
      <p>（4）“系统”及相关图形等为系统的注册商标；</p>
      <p>
        （5）未经系统许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，将依法追究法律责任。
      </p>
      <p>&nbsp;</p>
      <p>六、青少年用户特别提示</p>
      <p>青少年用户必须遵守全国青少年网络文明公约：</p>
      <p>
        要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。
      </p>
      <p>&nbsp;</p>
      <p>七、其他</p>
      <p>
        7.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。
      </p>
      <p>
        7.2
        系统未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。
      </p>
      <p>
        7.3
        如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'UserAgreement',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang='less'>
#agreement {
  padding: 20px;
  color: #3e4a59;
  font-family: '微软雅黑,PingFangSC-Medium,PingFangSC';
}
</style>
