// 正则检查身份证号码
export function checkIdCardIsOk(idCard) {
  var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return reg.test(idCard)
}

// 正则检查姓名
export function checkNameIsOk(name) {
  const reg = /^[\u4E00-\u9FA5]{2,4}$/
  return reg.test(name)
}

// 检查是否为微信浏览器
export function isWeixinBrowser() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('micromessenger') !== -1
}

// 检查是否为QQ浏览器
export function isQQBrowser() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('qq') !== -1 || ua.indexOf('mqqbrowser') !== -1
}

