import Vue from 'vue'
import VueRouter from 'vue-router'
import Splash from '@/views/Splash.vue'
import Layout from '@/views/Layout.vue'
import Product from '@/views/Product.vue'
import { getChannelConfig } from '@/api/user'

// 避免重复导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  // 如果要跳转的路径和当前路径一样，则不跳转
  if (this.currentRoute && location.path === this.currentRoute.path) {
    return Promise.resolve(this.currentRoute)
  }
  return originalPush.call(this, location).catch(err => {
    if (err.name === 'NavigationDuplicated') {
      return this.currentRoute
    }
    throw err
  })
}

Vue.use(VueRouter)

// 需要登录才能访问的路由
const authRoutes = ['home', 'my', 'product', 'auth', 'check']

// 不需要登录就能访问的路由
const publicRoutes = ['login', 'splash']

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: 'home',
    component: Layout,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home/:source?',
        props: true,
        component: () => import('@/views/Home.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'my/:source?',
        component: () => import('@/views/My.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/splash/:source?',
    props: true,
    component: Splash
  },
  {
    path: '/product',
    name: 'product',
    component: Product
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/Auth.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/check',
    name: 'check',
    component: () => import('@/views/CheckSuc.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login/:source?',
    props: true,
    name: 'login',
    component: () => import('@/views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// 全局导航守卫
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token')
  
  if (publicRoutes.includes(to.name)) {
    next()
    return
  }

  // 检查路由的 meta 字段
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const channel = localStorage.getItem('channel')
    if (!token) {
      next({
        name: 'login',
        params: { 
          source: channel || 'unknow',
          redirect: to.fullPath
        }
      })
      return
    }
  }
  next()
})

export default router
